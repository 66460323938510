import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  players = [];

  games = [{ label: "Bedwars", key: "bw", multiplier: 1, score: 0 }, { label: "Heroes", key: "he", multiplier: 1, score: 0 },
    { label: "Minecraft Party", key: "mp", multiplier: 1, score: 0 }, { label: "Rush", key: "rush", multiplier: 1, score: 0 },
    { label: "Stickfight", key: "sf", multiplier: 1, score: 0 }];

  constructor(public sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    this.generateOverview();
  }

  generateOverview() {
    console.debug("Generating overview...", this.sessionService.activeSession)
    if (!this.sessionService.activeSession) { // @ts-ignore
      return this.router.navigateByUrl("/");
    }
    const sessionData = this.sessionService.activeSession.data;
    this.games.forEach(game => game.score = 0);

    this.players = sessionData.players.map(player => {
      let overallScore: number = 0;

      const stages = sessionData.stages.map(stage => {
        console.log(stage)
        const games = stage.games.map((game, gameIndex) => {
          //console.log(stage.pre.find(stats => stats.player === player))
          //console.log(stage.pre);
          let preStats = (stage.pre.find(stats => stats.player === player)).stats[gameIndex] || {};
          preStats = preStats.points || 0;
          let afterStats = (stage.after.find(stats => stats.player === player)).stats[gameIndex] || {};
          afterStats = afterStats.points || 0;

          const gameObj = this.games.find(g => g.key === game);
          if (!gameObj) console.error("Game not found!!!!", gameObj);
          const gameData: any = { name: gameObj.label, pre: preStats, after: afterStats };
          gameData.score = gameData.after - gameData.pre;
          if (gameData.score < 0) gameData.score = 0;
          if (gameData.score === 0)  gameData.score++;
          gameData.score *= gameObj.multiplier;
          overallScore += gameData.score;
          gameObj.score += gameData.score;
          return gameData;
        });

        return { name: player, games };
      });

      console.log("Results: ", player, stages, overallScore);
      console.log("Game Balance: ", this.games);
      return { name: player, stages, overallScore };
    });

    this.players.sort((a, b) => b.overallScore - a.overallScore);
  }

  balanceGames() {
    console.log("Balancing games!");
    let highestGameScore = 0;
    this.games.forEach(game => {
      if (game.score > highestGameScore) highestGameScore = game.score;
    });

    this.games.forEach(game => {
      if (game.score === 0) return;
      game.multiplier = highestGameScore / game.score;
    });
    console.log("Gamebalance: ", this.games);
    this.generateOverview();
  }

}
