<div class="container">
  <div class="container-highlighted container-left playerInput">
    <h2 class="heading">Spielernamen</h2>

    <textarea class="playerInput__input" #playerList></textarea>
  </div>

  <div class="container-highlighted container-right">
    <h2 class="heading">Einstellungen</h2>

    <div class="games">
      <h3 class="subHeading">Wähle die zu speichernden Spiele</h3>
      <h4 class="game clickable" *ngFor="let game of games" (click)="game.track = !game.track"
          [ngClass]="{'game-active': game.track}">{{game.label}}</h4>
    </div>

    <button class="button clickable" (click)="createSession(playerList.value)">Start</button>
  </div>
</div>
