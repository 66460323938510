<div class="container">
  <div class="container-highlighted container-left">
    <h2 class="heading">Gespeicherte Sessions</h2>

    <cdk-virtual-scroll-viewport itemSize="120" class="session__viewport">
      <div class="session clickable" *cdkVirtualFor="let session of sessionService.savedSessions"
           (click)="sessionService.loadSession(session._id, session.token, $event)">
        <h2 class="session__title session__title-big">{{session?._id}}</h2>
        <h3 class="session__title session__title-normal">Erstellt am: {{session?.creationDateString}}</h3>

        <div class="session__buttons">
          <mat-icon class="session__button clickable" (click)="copySession(session)">content_copy</mat-icon>
          <mat-icon class="session__button clickable" (click)="dropSession(session, $event)">delete</mat-icon>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="container-right">
    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Neue Session erstellen</h2>

      <button class="button sessionFinder__button clickable" routerLink="/start" routerLinkActive="active">Session starten</button>
    </div>

    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Session laden</h2>

      <label class="sessionFinder__label" for="sessionIdInput">Session ID</label>
      <input type="text" class="input sessionFinder__input" id="sessionIdInput" #sessionIdInput>

      <button class="button sessionFinder__button clickable" (click)="findSession(sessionIdInput)">Session suchen</button>
    </div>
  </div>
</div>
