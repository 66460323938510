import { Component, OnInit } from "@angular/core";
import {SessionService} from '../../services/session.service';
import {SavedSession} from '../../types/session';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {

  savedSessions = [];

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

  async findSession(input: HTMLInputElement) {
    const value = input.value;
    const _id = value.split(":")[0];
    const token = value.split(":")[1];
    await this.sessionService.loadSession(_id, token, undefined)
    input.value = "";
  }

  async copySession(session: Partial<SavedSession>) {
    await navigator.clipboard.writeText(`${session._id}:${session.token}`);
  }

  async dropSession(session: Partial<SavedSession>, click: MouseEvent) {
    console.log("drop")
    if (click.shiftKey)
      await this.sessionService.deleteSession(session);
    else
      this.sessionService.dropSession(session);
  }

}
