import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {ImpressComponent} from "./components/impress/impress.component";
import {PrivacyComponent} from "./components/privacy/privacy.component";
import {HomeComponent} from "./components/home/home.component";
import {StartComponent} from "./components/start/start.component";
import {CollectionComponent} from './components/collection/collection.component';
import {EvaluationComponent} from './components/evaluation/evaluation.component';
import {NextComponent} from './components/next/next.component';
import {OverviewComponent} from './components/overview/overview.component';

const routes: Routes = [
  { path: "start", component: StartComponent },
  { path: "next", component: NextComponent },
  { path: "collection", component: CollectionComponent },
  { path: "evaluation", component: EvaluationComponent },
  { path: "overview", component: OverviewComponent },
  { path: "impress", component: ImpressComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "**", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
