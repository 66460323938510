import { Injectable } from '@angular/core';
import {APIError, SavedSession} from '../types/session';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class SessionService {

  baseUrl: string = "https://tempstorage.dmnkapi.com/";
  //baseUrl: string = "http://localhost:8080"
  savedSessions: Partial<SavedSession>[] = [];
  activeSession: Partial<SavedSession>;

  constructor(private http: HttpClient, private router: Router) {
    if (localStorage.getItem("sessions"))
      this.savedSessions = JSON.parse(localStorage.getItem("sessions"))
    this.savedSessions = this.savedSessions.filter(saveSession => saveSession.life > Date.now());
    this.savedSessions.forEach(session => session.creationDateString = new Date(session.creationDate).toLocaleString());
    this.savedSessions.sort((a, b) => b.creationDate - a.creationDate);
    console.log(this.savedSessions)
  }

  saveSessionsLocal() {
    const sessions = this.savedSessions.map(s => { return { _id: s._id, token: s.token, creationDate: s.creationDate, life: s.life } });
    localStorage.setItem("sessions", JSON.stringify(sessions));
  }

  async saveSession(): Promise<Partial<SavedSession>> {
    console.debug("Saving session...", this.activeSession);
    if (this.activeSession._id) {
      const savedSession: any = await this.http.put(`${this.baseUrl}data/${this.activeSession._id}/${this.activeSession.token}`,
        { data: this.activeSession.data }).toPromise();
      if (!savedSession.success) {
        console.error(savedSession);
        return undefined;
      }
      this.activeSession = savedSession.data;
      this.saveSessionsLocal();
      console.debug(this.activeSession);
    } else {
      const savedSession: any = await this.http.post(this.baseUrl + "data", { isPublic: false, data: this.activeSession.data }).toPromise();
      if (!savedSession.success) {
        console.error(savedSession);
        return undefined;
      }
      this.activeSession = savedSession.data;
      this.savedSessions.push(savedSession.data);
      this.saveSessionsLocal();
      console.debug(this.activeSession);
    }
    return this.activeSession
  }

  async loadSession(_id: string, token: string, click: MouseEvent) {
    // @ts-ignore
    if (click && click.target?.classList.contains("session__button")) return;
    console.debug(_id, token);
    // @ts-ignore
    const session: SavedSession | APIError = await this.http.get(`${this.baseUrl}data/${_id}/${token}`).toPromise();
    console.debug(session);
    if ("errorCode" in session) return undefined;
    session.token = token;
    if (!this.savedSessions.find(s => s._id === session._id)) {
      this.savedSessions.push(session);
      this.saveSessionsLocal();
    }

    this.activeSession = session;

    if (session.data.stage === "initialized" || session.data.stage === "collected")
      return this.router.navigateByUrl("/collection");
    return this.router.navigateByUrl("/collection");
  }

  async gatherStats(stage: any, type) {
    const sessionData = this.activeSession.data;
    const headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    const stats = [];

    for (let player of sessionData.players) {
      await new Promise(r => setTimeout(r, 500));
      const playerStats: any = await this.http.get(`https://cxnmp-playerdata.dmnkapi.com/name/${player}`, { headers }).toPromise();
      console.log(player, playerStats);
      stats.push({ player, stats: stage.games.map(game => playerStats.stats[game].daily) });
    }
    console.log("Stats for current Stage:", stats);
    stage[type] = stats;
    return stage;
  }

  async deleteSession(session: Partial<SavedSession>) {
    const httpResponse = await this.http.delete(`${this.baseUrl}data/${session._id}/${session.token}`).toPromise();
    if ("errorCode" in httpResponse) {
      console.error("Can't delete session", httpResponse);
      return;
    }
    this.dropSession(session);
  }

  dropSession(session: Partial<SavedSession>) {
    this.savedSessions = this.savedSessions.filter(s => s._id !== session._id);
    this.saveSessionsLocal();
  }

}
