<div class="container">
  <div class="container-highlighted container-left">
    <h2 class="heading">Ergebnis</h2>


    <cdk-virtual-scroll-viewport itemSize="120" class="player__viewport">
      <div class="player" *cdkVirtualFor="let player of players">
        <h2 class="player__title player__title-big">{{player.name}}</h2>
        <h3 class="player__title player__title-normal" *ngFor="let game of player.games">{{game.name}}: {{game.score}}</h3>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="container-right">
    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Weitere Runde auswerten</h2>

      <button class="button sessionFinder__button clickable" routerLink="/next" routerLinkActive="active">Runde hinzufügen</button>
    </div>

    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Ergebnis ermitteln</h2>

      <button class="button sessionFinder__button clickable" routerLink="/overview" routerLinkActive="active">Ergebnis anzeigen</button>
    </div>
  </div>
</div>
