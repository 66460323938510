<div class="container">
  <h1 class="heading">Datenschutzerklärung - Privacy Regulation</h1>
  <h2 class="subHeading">Inhalt</h2>
  <div class="container-right">
    <p>Wie wir mit Ihren personenbezogenen Daten umgehen, erläutern wir Ihnen in dieser Datenschutzerklärung. Maßgabe ist das geltende Datenschutzrecht, insbesondere die Datenschutz-Grundverordnung (DSGVO). Mit Ausnahme der Dienstleister und Drittanbieter, die wir in dieser Datenschutzerklärung benennen, geben wir keine Daten an Dritte weiter. Wenn Sie Fragen haben, sprechen Sie uns gerne an.</p>
  </div>

  <ul>
    <li><a class="link" (click)="scrollToElement(e1)">Verantwortlicher</a></li>
    <li><a class="link" (click)="scrollToElement(e2)">Allgemeine Informationen</a></li>
    <li><a class="link" (click)="scrollToElement(e3)">Webseitenhosting</a></li>
    <li><a class="link" (click)="scrollToElement(e5)">Kontaktaufnahme</a></li>
    <li><a class="link" (click)="scrollToElement(e6)">Kommentare</a></li>
    <li><a class="link" (click)="scrollToElement(e7)">Registrierung für ein Benutzerkonto</a></li>
    <li><a class="link" (click)="scrollToElement(e8)">Weitere Drittdienste</a></li>
    <li><a class="link" (click)="scrollToElement(e9)">Rechte betroffener Personen</a></li>
  </ul>

  <h2 class="subHeading" #e1>Verantwortlicher</h2>
  <div class="container-right">
    <p>Verantwortlich für die Datenverarbeitung ist</p>
    <p><strong>E-Mail-Adresse:</strong><a class="link" href="mailto:support@dmnkdev.com"> support@dmnkdev.com</a></p>
    <p>Dominik Fürst</p>
    <p>Franz-Joseph-Str. 11</p>
    <p>80801 München</p>
  </div>

  <h2 class="subHeading" #e2>Allgemeine Informationen</h2>
  <div class="container-right">
    <h3>Bereitstellung von Daten</h3>
    <p>Für eine Nutzung unserer Webseite ist in der Regel weder gesetzlich noch vertraglich vorgeschrieben, personenbezogene Daten zur Verfügung stellen. Soweit eine Bereitstellung von Daten für einen Vertragsabschluss erforderlich ist oder der Nutzer dazu verpflichtet ist, personenbezogene Daten bereitzustellen, teilen wir diesen Umstand und welche Folgen die Nichtbereitstellung hätte in dieser Datenschutzerklärung mit.</p>
    <h3>Datenübermittlung an Drittländer</h3>
    <p>Es ist möglich, dass wir Dienstleister und Drittanbieter einsetzen, die in Ländern außerhalb der Europäischen Union und des Europäischen Wirtschaftsraums ansässig sind. Die Übermittlung personenbezogener Daten in solche Drittländer erfolgt auf der Grundlage eines Angemessenheitsbeschlusses der Europäischen Kommission (Art.&nbsp;45 DSGVO) oder wir haben geeignete Garantien vorgesehen, um den Datenschutz sicherzustellen (Art.&nbsp;46 DSGVO). Soweit ein Angemessenheitsbeschluss der Europäischen Kommission für die Datenübermittlung in ein Drittland vorhanden ist, weisen wir in dieser Datenschutzerklärung darauf hin. Im Übrigen gilt, dass Nutzer eine Kopie der geeigneten Garantien, soweit diese nicht bereits in den Datenschutzerklärungen der Dienstleister oder Drittanbieter enthalten ist, über uns beziehen können.</p>
    <h3>Automatisierte Entscheidungsfindung</h3>
    <p>Sollten wir eine automatisierte Entscheidungsfindung einschließlich Profiling vornehmen, informieren wir in dieser Datenschutzerklärung über diesen Umstand, über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung. Im Übrigen findet eine automatisierte Entscheidungsfindung nicht statt.</p>
    <h3>Verarbeitung zu anderen Zwecken</h3>
    <p>Daten werden grundsätzlich nur zu den Zwecken verarbeitet, für die sie erhoben wurden. Sollen sie ausnahmsweise zu anderen Zwecken weiterverarbeitet werden, informieren wir vor dieser Weiterverarbeitung über diese anderen Zwecke und stellen alle anderen maßgeblichen Informationen zur Verfügung (Art.&nbsp;13 Abs.&nbsp;3 DSGVO).</p>
  </div>

  <h2 class="subHeading" #e3>Webseitenhosting</h2>
  <div class="container-right">
    <ul>
      <li>Browsertyp und verwendete -version</li>
      <li>Betriebssystem</li>
      <li>Abgerufene Seiten und Dateien</li>
      <li>Übertragene Datenmenge</li>
      <li>Datum und Uhrzeit des Abrufs</li>
      <li>Provider des Nutzers</li>
      <li>IP-Adresse in anonymisierter Form</li>
      <li>Referrer URL</li>
    </ul>
    <p>Die Verarbeitung dieser Daten ist erforderlich, um die Webseite an den Nutzer ausliefern zu können und für sein Endgerät zu optimieren. Die Speicherung in Logfiles dient der Verbesserung der Sicherheit unserer Webseite (z.B. Schutz vor DDOS-Angriffen). IP-Adressen werden vor ihrer Speicherung in Logfiles anonymisiert.</p>
    <p>Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Bereitstellung der Webseite und der Verbesserung der Webseitensicherheit. Logfiles werden nach 14 Tagen automatisch gelöscht.</p>
  </div>

  <h2 class="subHeading" #e5>Kontaktaufnahme</h2>
  <div class="container-right">
    <p>Im Falle einer Kontaktaufnahme verarbeiten wir die Angaben des Nutzers, Datum und Uhrzeit zum Zwecke der Bearbeitung der Anfrage einschließlich eventueller Rückfragen.</p>
    <p>Rechtsgrundlage für die Datenverarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Beantwortung der Anfragen unserer Nutzer. Zusätzliche Rechtsgrundlage ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;b) DSGVO, wenn die Verarbeitung für die Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.</p>
    <p>Die Daten werden gelöscht, sobald die Anfrage einschließlich etwaiger Rückfragen beantwortet ist. Wir überprüfen in regelmäßigen Abständen, mindestens aber alle zwei Jahre, ob im Zusammenhang mit Kontaktaufnahmen angefallene Daten zu löschen sind.</p>
  </div>

  <h2 class="subHeading" #e6>Kommentare</h2>
  <div class="container-right">
    <p>Auf unserer Webseite geben wir Nutzern die Möglichkeit, eigene Kommentare zu hinterlassen. Wenn ein Kommentar an uns übermittelt wird, verarbeiten wir die Angaben des Nutzers. Zum Schutz vor einem Missbrauch der Kommentarfunktion (z.B. durch Spam oder strafbare Inhalte) verarbeiten wir zusätzlich Datum, Uhrzeit und die IP-Adresse des Nutzers.</p>
    <p>Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht darin, die Kommentarfunktion anbieten zu können und uns vor einem Missbrauch zu schützen.</p>
  </div>

  <h2 class="subHeading" #e7>Registrierung für ein Benutzerkonto</h2>
  <div class="container-right">
    <p>Nutzer können sich auf unserer Webseite für unser Angebot registrieren. In diesem Zusammenhang verarbeiten wir die bei der Anmeldung eingegebenen Daten. Die E-Mail-Adresse lassen wir uns durch Zusendung eines Links bestätigen (Double-Opt-In), um einen Missbrauch der Registrierungsfunktion zu verhindern. Weiter verarbeiten wir zu diesem Zweck Datum und Uhrzeit und die IP-Adresse des Nutzers. Zu Nachweiszwecken verarbeiten wir bei einem Klick auf den Bestätigungslink ferner Datum, Uhrzeit und IP-Adresse des Nutzers.</p>
    <p>Die Daten werden mit der Löschung des Benutzeraccounts nach Ablauf von drei Jahren zum Schluss des Jahres gelöscht, wenn nicht eine längere gesetzliche Aufbewahrungspflicht entgegensteht.</p>
    <p>Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;a) DSGVO, soweit wir eine Einwilligung des Nutzers einholen. Ist die Verarbeitung für die Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, beruht sie auf Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;b) DSGVO. Rechtsgrundlage im Übrigen ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht darin, den Nutzern einen Zugang zu unserem registrierungspflichtigen Angebot zu ermöglichen, uns vor einem Missbrauch der Registrierungsfunktion zu schützen und eine ordnungsgemäße Eintragung nachweisen zu können. Nach der Löschung des Benutzeraccounts besteht unser berechtigtes Interesse überdies in der Abwehr möglicher Ansprüche.</p>
  </div>

  <h2 class="subHeading" #e8>Weitere Drittdienste</h2>
  <div class="container-right">
    <h3>Cloudflare</h3>
    <p>Wir nutzen das Content-Delivery-Network (CDN) Cloudflare CDN. Anbieter: Cloudflare Inc., 101 Townsend Street, San Francisco, California 94107.</p>
    <p>Inhalte werden von Servern des CDN geladen. Damit eine Verbindung aufgebaut werden kann, ist es technisch notwendig, die IP-Adresse des Nutzers zu übertragen.</p>
    <p>Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Verbesserung der Geschwindigkeit und Verfügbarkeit unserer Webseite.</p>
    <p><a class="link" href="https://www.cloudflare.com/de-de/privacypolicy/">Datenschutzerklärung von Cloudflare</a></p>
    <br>
    <h3>Google Fonts</h3>
    <p>Auf unserer Webseite nutzen wir Google Fonts. Anbieter: Google Ireland Ltd., Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland.</p>
    <p>Schriftarten werden vom Google-Server geladen. Um eine Verbindung zum Server aufzubauen, ist es technisch notwendig, die IP-Adresse des Nutzers zu übertragen.</p>
    <p>Rechtsgrundlage der Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;f) DSGVO. Unser berechtigtes Interesse besteht in der Verkürzung von Ladezeiten und einer einheitlichen Darstellung auf unterschiedlichen Endgeräten.</p>
    <p><a class="link" href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung von Google Fonts</a></p>
  </div>

  <h2 class="subHeading" #e9>Rechte betroffener Personen</h2>
  <div class="container-right">
    <p>Werden personenbezogene Daten des Nutzers verarbeitet, ist er betroffene Person im Sinne der DSGVO. Betroffenen Personen stehen die folgenden Rechte zu:</p>
    <h3>Recht auf Auskunft:</h3>
    <p>Die betroffene Person hat das Recht, eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Werden personenbezogene Daten verarbeitet, so hat die betroffene Person ein Recht auf unentgeltliche Auskunft sowie auf eine Kopie der personenbezogenen Daten, die Gegenstand der Verarbeitung sind.</p>
    <h3>Recht auf Berichtigung:</h3>
    <p>Die betroffene Person hat das Recht, die unverzügliche Berichtigung unrichtiger oder Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</p>
    <h3>Recht auf Löschung:</h3>
    <p>Die betroffene Person hat das Recht, nach Maßgabe der gesetzlichen Bestimmungen eine unverzügliche Löschung sie betreffender personenbezogener Daten zu verlangen.</p>
    <h3>Recht auf Einschränkung der Verarbeitung:</h3>
    <p>Die betroffene Person hat das Recht, nach Maßgabe der gesetzlichen Bestimmungen eine Einschränkung der Verarbeitung sie betreffender personenbezogener Daten zu verlangen.</p>
    <h3>Recht auf Datenübertragbarkeit:</h3>
    <p>Die betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder eine Übermittlung an einen anderen Verantwortlichen zu verlangen.</p>
    <h3>Recht auf Widerspruch:</h3>
    <p>Die betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1 UAbs.&nbsp;1 Buchst.&nbsp;e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>
    <h3>Recht auf Widerruf:</h3>
    <p>Die betroffene Person hat das Recht, ihre erteilte Einwilligung jederzeit zu widerrufen.</p>
    <h3>Recht auf Beschwerde:</h3>
    <p>Die betroffene Person hat das Recht, sich bei einer Aufsichtsbehörde zu beschweren.</p>
  </div>

  <p>Stand der Datenschutzerklärung: 05. Januar 2021</p>
  <p>Diese Datenschutzerklärung wurde mit dem Generator von <a class="link link-noHighlight" href="https://www.einfach-abmahnsicher.de">Einfach Abmahnsicher</a> erstellt.</p>
</div>

<footer class="footer">
  <a class="footer__info" routerLink="/impress" routerLinkActive="active">Impressum</a>
  <a class="footer__info" routerLink="/privacy" routerLinkActive="active">Datenschutz</a>
</footer>
