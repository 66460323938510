import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';
import {Session} from '../../types/session';

@Component({
  selector: 'app-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss']
})
export class NextComponent implements OnInit {

  games = [{ label: "Bedwars", key: "bw", track: false }, { label: "Heroes", key: "he", track: false },
    { label: "Minecraft Party", key: "mp", track: false }, { label: "Rush", key: "rush", track: false },
    { label: "Stickfight", key: "sf", track: false }];

  session: any = { players: [], stages: [], stage: "selected" }

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    console.log("Current Session(Next):", this.sessionService.activeSession);
  }

  async createStage() {
    const nextStage: any = { pre: undefined, after: undefined, summary: undefined };
    const activeGames = this.games.filter(game => game.track);
    if (activeGames.length === 0) return console.error("No game selected!");
    nextStage.games = activeGames.map(game => game.key);
    //this.sessionService.activeSession.data.stages.push(nextStage);

    const stageWData = await this.sessionService.gatherStats(nextStage, "pre");
    console.log("New Stage with data:", stageWData);
    this.sessionService.activeSession.data.stages.push(stageWData);
    this.sessionService.activeSession.data.stage = "initialized";
    //this.sessionService.activeSession = { data: sessionData };
    await this.sessionService.saveSession();
    await this.router.navigateByUrl("/collection")
  }

}
