import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ImpressComponent } from "./components/impress/impress.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { HomeComponent } from './components/home/home.component';
import { StartComponent } from './components/start/start.component';
import { OverviewComponent } from './components/overview/overview.component';
import { CollectionComponent } from './components/collection/collection.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { NextComponent } from './components/next/next.component';

@NgModule({
  declarations: [
    AppComponent,
    ImpressComponent,
    PrivacyComponent,
    HomeComponent,
    StartComponent,
    OverviewComponent,
    CollectionComponent,
    EvaluationComponent,
    NextComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
    ScrollingModule,
    MatIconModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
