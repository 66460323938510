<div class="container">
  <div class="container-highlighted container-left">
    <h2 class="heading">Ergebnis</h2>

    <cdk-virtual-scroll-viewport itemSize="120" class="player__viewport">
      <div class="player" *cdkVirtualFor="let player of players">
        <h2 class="player__title player__title-big">{{player.name}}</h2>
        <div class="player__stage" *ngFor="let stage of player.stages; let index = index">
          <h3 class="player__title player__title-normal" *ngFor="let game of stage.games">{{game.name}}: {{game.score}}</h3>
        </div>
        <h3 class="player__title player__title-normal">Gesamt: {{player.overallScore}}</h3>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="container-right">
    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Spielbalance</h2>

      <div class="games">
        <div>
          <h4 class="game__text" *ngFor="let game of games">{{game.label}}</h4>
        </div>
        <div>
          <h4 class="game__text" *ngFor="let game of games">Score: {{game.score}}</h4>
        </div>
        <div>
          <h4 class="game__text" *ngFor="let game of games">Multiplier: {{game.multiplier}}</h4>
        </div>
      </div>
    </div>

    <div class="container-highlighted sessionFinder container-small">
      <h2 class="heading sessionFinder__heading">Spiele angleichen</h2>

      <button (click)="balanceGames()" class="button sessionFinder__button clickable">Angleichen</button>
    </div>
  </div>
</div>
