import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {

  players = [];

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit() {
    console.debug("EvaluationComponent")
    if (!this.sessionService.activeSession) return this.router.navigateByUrl("/");
    const sessionData = this.sessionService.activeSession.data;
    const stageCount: number = sessionData.stages.length - 1;

    this.players = sessionData.players.map(player => {
      let overallScore: number = 0;
      const games = sessionData.stages[stageCount].games.map((game, gameIndex) => {
        console.log(sessionData.stages[stageCount].pre.find(stats => stats.player === player))
        console.log(sessionData.stages[stageCount].pre);
        let preStats = (sessionData.stages[stageCount].pre.find(stats => stats.player === player)).stats[gameIndex] || {};
        preStats = preStats.points || 0;
        let afterStats = (sessionData.stages[stageCount].after.find(stats => stats.player === player)).stats[gameIndex] || {};
        afterStats = afterStats.points || 0;

        const gameData: any = { name: game, pre: preStats, after: afterStats };
        gameData.score = gameData.after - gameData.pre;
        overallScore += gameData.score;
        return gameData;
      });
      console.log({ name: player, games })
      return { name: player, games, overallScore };
    });

    this.players.sort((a, b) => b.overallScore - a.overallScore);
  }



}
