import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {

  playerData;

  constructor(public sessionService: SessionService, private router: Router) { }

  ngOnInit() {
    console.debug("CollectionComponent")
    console.log(this.sessionService.activeSession)
    if (!this.sessionService.activeSession) return this.router.navigateByUrl("/");
    const sessionData = this.sessionService.activeSession.data;
    let currentStep = "pre";
    if (sessionData.stage === "collected") currentStep = "after";
    this.playerData = sessionData.players.map(player => sessionData.stages[sessionData.stages.length - 1][currentStep].find(x => x.player === player));
    console.debug(this.playerData);
  }

  async continue() {
    const sessionData = this.sessionService.activeSession.data;
    if (sessionData.stage === "initialized") {
      const step: number = sessionData.stages.length - 1;
      sessionData.stages[step] = await this.sessionService.gatherStats(sessionData.stages[step], "after");
      sessionData.stage = "collected";
      console.debug(sessionData.stages[step]);
      console.debug(sessionData.stages);
      this.sessionService.activeSession.data = sessionData;
      await this.sessionService.saveSession();
      //await this.router.navigateByUrl("collection");
      await this.ngOnInit();
    } else if (sessionData.stage === "collected") {
      await this.router.navigateByUrl("evaluation");
    }
  }

}
