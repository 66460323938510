<div class="container">
  <div class="container-highlighted container-left">
    <h2 class="heading" *ngIf="sessionService?.activeSession?.data?.stage !== 'collected' &&
     sessionService?.activeSession?.data?.stage !== 'initialized'" >STATUS UNBEKANNT!!</h2>
    <h2 class="heading" *ngIf="sessionService?.activeSession?.data?.stage === 'initialized'">Startwerte</h2>
    <h2 class="heading" *ngIf="sessionService?.activeSession?.data?.stage === 'collected'">Endwerte</h2>

    <cdk-virtual-scroll-viewport itemSize="120" class="player__viewport">
      <div class="player" *cdkVirtualFor="let player of playerData">
        <h2 class="player__title player__title-big">{{player?.player}}</h2>
        <h3 class="player__title player__title-normal" *ngFor="let stats of player.stats">Punkte: {{stats.points}}</h3>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="container-right">
    <div class="container-highlighted sessionFinder">
      <h2 class="heading sessionFinder__heading">Auswerten</h2>

      <button class="button sessionFinder__button clickable" (click)="continue()">Jetzt auswerten</button>
    </div>
  </div>
</div>
